import email from '../assets/email.png'
import mobile from '../assets/mobile.png'
import figma from '../assets/figma.png'
import googleAds from '../assets/google-ads.png'
import googleAnalytics from '../assets/google-analytics.png'
import meta from '../assets/meta.png'
import linkedin from '../assets/linkedin.png'

import about01 from '../assets/about01.png'
import about02 from '../assets/about02.png'
import about03 from '../assets/about03.png'
import about04 from '../assets/about04.png'

import profile from '../assets/profile.png'
import circle from '../assets/circle.svg'
import logo from '../assets/logo.svg'

import adidas from '../assets/adidas.png'
import amazon from '../assets/amazon.png'
import bolt from '../assets/bolt.png'
import nb from '../assets/nb.png'
import skype from '../assets/skype.png'
import spotify from '../assets/spotify.png'

export default {
  mobile,
  email,
  figma,
  about01,
  about02,
  about03,
  about04,
  profile,
  circle,
  logo,
  adidas,
  amazon,
  bolt,
  nb,
  skype,
  spotify,
  googleAds,
  googleAnalytics,
  linkedin,
  meta,
}
