import React from "react";
import { BsLinkedin, BsFillFileEarmarkTextFill } from "react-icons/bs";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const SocialMedia = () => (
	<div className="app__social">
		<a
			href="https://drive.google.com/file/d/1U1DTz_iFS0xiN6xeasDrI-f97Zn0uEwa/view?usp=sharing"
			target="_blank"
			rel="noopener noreferrer"
		>
			<Tippy content="My CV" className="tippy-tooltip" placement="right">
				<div>
					<BsFillFileEarmarkTextFill />
				</div>
			</Tippy>
		</a>

		<a
			href="https://www.linkedin.com/in/ritamcosta"
			target="_blank"
			rel="noopener noreferrer"
		>
			<Tippy content="My LinkedIn" className="tippy-tooltip" placement="right">
				<div>
					<BsLinkedin />
				</div>
			</Tippy>
		</a>
	</div>
);

export default SocialMedia;
